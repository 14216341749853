/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)

// You can specify which plugins you need
import { Tooltip, Toast, Popover } from "bootstrap";
import './styles/app.scss';
import 'bootstrap';
import $ from "jquery";
global.$ = global.jQuery = window.$ = window.jQuery = $; // Set jQuery as global
import 'bootstrap';

require('bootstrap');
// or you can include specific pieces
require("datatables.net-bs5");
require("bootstrap5-toggle");

window.innerWidth < 768 &&
  [].slice
    .call(document.querySelectorAll("[data-bss-disabled-mobile]"))
    .forEach(function (e) {
      e.classList.remove("animated"),
        e.removeAttribute("data-bss-hover-animate"),
        e.removeAttribute("data-aos"),
        e.removeAttribute("data-bss-parallax-bg"),
        e.removeAttribute("data-bss-scroll-zoom");
    }),
  document.addEventListener(
    "DOMContentLoaded",
    function () {
      [].slice
        .call(document.querySelectorAll("[data-bss-hover-animate]"))
        .forEach(function (e) {
          e.addEventListener("mouseenter", function (e) {
            e.target.classList.add(
              "animated",
              e.target.dataset.bssHoverAnimate
            );
          }),
            e.addEventListener("mouseleave", function (e) {
              e.target.classList.remove(
                "animated",
                e.target.dataset.bssHoverAnimate
              );
            });
        });
    },
    !1
  );

$(document).ready(function () {
  $("#tbc tfoot th").each(function () {
    var title = $(this).text();
    $(this).html(
      '<input class="form-control" type="text" placeholder="' + title + '" />'
    );
  });

  let tgBtns = document.querySelectorAll(".togglePassword");
  tgBtns.forEach((e) => {
  e.addEventListener("click", function (e) {
      let t = $('#inputPassword');
      e = document.querySelector("#eye-icon");
      "password" == t.attr("type")
      ? $('#inputPassword').attr("type", "text")
      : $('#inputPassword').attr("type", "password"),
      e.classList.toggle("ion-eye-disabled"),
      e.classList.toggle("ion-eye");
  });
  });

  var tbUsager = $("#tbUsagers").dataTable({
    pageLength: 25,
    language: {
      url: "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/French.json",
    },
    initComplete: function () {
      // Apply the search
      this.api()
        .columns()
        .every(function () {
          var that = this;
          $("input", this.footer()).on("keyup change clear", function () {
            if (that.search() !== this.value) {
              that.search(this.value).draw();
            }
          });
        });
    },
  });

  var tbUsers = $("#tbUsers").dataTable({
    pageLength: 25,
    language: {
      url: "//cdn.datatables.net/plug-ins/2.0.5/i18n/fr-FR.json",
    },
    initComplete: function () {
      // Apply the search
      this.api()
        .columns()
        .every(function () {
          var that = this;
          $("input", this.footer()).on("keyup change clear", function () {
            if (that.search() !== this.value) {
              that.search(this.value).draw();
            }
          });
        });
    },
  });
});

var tableUserHistory = $("#tbConsoUsager").dataTable({
  pageLength: 15,
  language: {
    url: "//cdn.datatables.net/plug-ins/2.0.5/i18n/fr-FR.json",
  },
  initComplete: function () {
    // Apply the search
    this.api()
      .columns()
      .every(function () {
        var that = this;
        $("input", this.footer()).on("keyup change clear", function () {
          if (that.search() !== this.value) {
            that.search(this.value).draw();
          }
        });
      });
  },
});

var tableUserActionHistory = $("#tbHistoUsager").dataTable({
  pageLength: 15,
  language: {
    url: "//cdn.datatables.net/plug-ins/2.0.5/i18n/fr-FR.json",
  },
  initComplete: function () {
    // Apply the search
    this.api()
      .columns()
      .every(function () {
        var that = this;
        $("input", this.footer()).on("keyup change clear", function () {
          if (that.search() !== this.value) {
            that.search(this.value).draw();
          }
        });
      });
  },
});

var tableHistoPort = $("#tbHistoPort").dataTable({
  pageLength: 25,
  language: {
    url: "//cdn.datatables.net/plug-ins/2.0.5/i18n/fr-FR.json",
  },
  initComplete: function () {
    // Apply the search
    this.api()
      .columns()
      .every(function () {
        var that = this;
        $("input", this.footer()).on("keyup change clear", function () {
          if (that.search() !== this.value) {
            that.search(this.value).draw();
          }
        });
      });
  },
});

var tableCollectivite = $("#tbCollectitive").dataTable({
  pageLength: 25,
  language: {
    url: "//cdn.datatables.net/plug-ins/2.0.5/i18n/fr-FR.json",
  },
  initComplete: function () {
    // Apply the search
    this.api()
      .columns()
      .every(function () {
        var that = this;
        $("input", this.footer()).on("keyup change clear", function () {
          if (that.search() !== this.value) {
            that.search(this.value).draw();
          }
        });
      });
  },
});

var tablePort = $("#tbPort").dataTable({
  pageLength: 25,
  language: {
    url: "//cdn.datatables.net/plug-ins/2.0.5/i18n/fr-FR.json",
  },
  initComplete: function () {
    // Apply the search
    this.api()
      .columns()
      .every(function () {
        var that = this;
        $("input", this.footer()).on("keyup change clear", function () {
          if (that.search() !== this.value) {
            that.search(this.value).draw();
          }
        });
      });
  },
});

var tableHistoBorne = $("#tbHistoBorne").dataTable({
  pageLength: 25,
  language: {
    url: "//cdn.datatables.net/plug-ins/2.0.5/i18n/fr-FR.json",
  },
  initComplete: function () {
    // Apply the search
    this.api()
      .columns()
      .every(function () {
        var that = this;
        $("input", this.footer()).on("keyup change clear", function () {
          if (that.search() !== this.value) {
            that.search(this.value).draw();
          }
        });
      });
  },
});

var tableAlarme = $("#tbAlarme").dataTable({
  pageLength: 25,
  language: {
    url: "//cdn.datatables.net/plug-ins/2.0.5/i18n/fr-FR.json",
  },
  initComplete: function () {
    // Apply the search
    this.api()
      .columns()
      .every(function () {
        var that = this;
        $("input", this.footer()).on("keyup change clear", function () {
          if (that.search() !== this.value) {
            that.search(this.value).draw();
          }
        });
      });
  },
});

